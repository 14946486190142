<template>
  <div class="block">
    <el-page-header @back="dynamic" content="油卡配置" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text" @click="outerVisible = true">
        <el-button type="primary" size="mini">油卡充值</el-button>
      </el-button>

      <el-dialog title="添加" :visible.sync="outerVisible">
        <el-form :model="form">
          <el-form-item  label="卡号" :label-width="formLabelWidth"  class="w-50">
            <el-select v-model="form.cardId" placeholder="请选择">
              <el-option
                  v-for="item in cardData"
                  :key="item.cardId"
                  :label="item.cardName"
                  :value="item.cardId">
                <span style="float: left">{{ item.cardName }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cardNum }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="充值金额" :label-width="formLabelWidth"  class="w-50">
            <el-input v-model="form.balance" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <el-dialog
            width="20%"
            title="警告"
            :visible.sync="innerVisible"
            append-to-body>
          <span>您将新增一条记录，请确认！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false;outerVisible = false">取 消</el-button>
            <el-button type="primary" @click="onSubmit();innerVisible = false;outerVisible = false">确 定</el-button>
          </span>
        </el-dialog>
        <div slot="footer" class="dialog-footer">
          <el-button @click="outerVisible = false">取 消</el-button>
          <el-button type="primary" @click="innerVisible = true">提交</el-button>
        </div>
      </el-dialog>
    </template>
    <el-table
        :data="cardData"
        style="width: 100%; margin: auto"
        height="456">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="cardNum"
          label="卡号"
          width="220">
      </el-table-column>
      <el-table-column
          prop="cardName"
          label="卡名称"
          width="120">
      </el-table-column>
      <el-table-column
          prop="balance"
          label="余额"
          width="100">
        <template slot-scope="scope3">
          <span v-if="(scope3.row.balance <= 400)" style="color: red">{{scope3.row.balance}}</span>
          <span v-if="(scope3.row.balance > 400)">{{scope3.row.balance}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="cardExp"
          label="使用范围">
      </el-table-column>
      <el-table-column
          prop="createDate"
          label="创建日期"
          width="120">
      </el-table-column>
      <el-table-column
          label="操作"
          width="100">
        <template slot-scope="scope1" flex >
          <el-button type="text" icon="el-icon-edit" @click.native.prevent="cardOut = true;deleteRow(scope1.row.cardId)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加" :visible.sync="cardOut">
      <el-form :model="cardDataById">
        <el-form-item label="卡号" :label-width="formLabelWidth"  class="w-50">
          <el-input v-model="cardDataById.cardNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="卡名称" :label-width="formLabelWidth"  class="w-50">
          <el-input v-model="cardDataById.cardName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="使用范围" :label-width="formLabelWidth"  class="w-50">
          <el-input v-model="cardDataById.cardExp" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <el-dialog
          width="20%"
          title="警告"
          :visible.sync="cardIn"
          append-to-body>
        <span>您将新增一条记录，请确认！</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cardIn = false;cardOut = false">取 消</el-button>
            <el-button type="primary" @click="omdefyCard();cardIn = false;cardOut = false">确 定</el-button>
          </span>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cardOut = false">取 消</el-button>
        <el-button type="primary" @click="cardIn = true">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "card",
  data() {
    return {
      cardOut: false,
      cardIn: false,
      outerVisible: false,
      innerVisible: false,
      cardDataById: {
        cardId: '',
        cardNum: '',
        cardName: '',
        cardExp: '',
        balance: '',
        CreateDate: '',
        DepId: ''
      },
      form: {
        cardId: '',
        balance: ''
      },
      formLabelWidth: '120px',
      cardData: []
    }
  },
  mounted() {
    this.getCardData()
  },
  methods: {
    onSubmit() {
      this.$http({
        method: 'post',
        url: '/api/card/addOilCard',
        data: this.form
      }).then((res) =>{
        alert(res.data.msg)
        if (res.data.status) {
          this.form = {}
          this.getCardData()
        }
      })
    },
    dynamic() {
      this.$router.push("dynamic")
    },
    getCardData() {
      this.$http({
        method: 'get',
        url: '/api/card/getCardList',
        data: []
      }).then((res)=>{
        this.cardData = res.data.data
      })
    },
    deleteRow(cardId) {
      if (cardId != null) {
        this.$http({
          method: 'get',
          url: '/api/card/getCardById/' + cardId,
          data: ''
        }).then((res) =>{
          this.cardDataById = res.data.data
        })
      }
    },
    omdefyCard() {
      this.$http({
        method: 'post',
        url: '/api/card/modefyCard',
        data: this.cardDataById
      }).then((res) =>{
        alert(res.data.msg)
        this.getCardData()
      })
    }
  }
};
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>